<template>
    <div class="scan-aspect">
        <div class="scan-aspect__inner">
            <h3 class="text-primary mb-4">{{ aspectNumber }}. {{ aspect.title }}</h3>

            <div class="row scan-aspect__levels">
                <div v-for="level in aspect.levels" :key="level.description" class="col-12 col-lg-4 level" :class="{ 'level--from': level.isFrom, 'level--to': level.isTo, 'level--disabled': isLevelDisabled(level) }" @click="selectLevel(level)">
                    <div class="level__inner">
                        <div class="level__inner--block">
                            <h5 class="mb-2">{{ level.title }}</h5>
                            <p>{{ level.description }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="hasSettings()" class="pt-2 text-right"><a href="#" @click.prevent="resetAspect()">Reset</a></div>
            <AutogrowTextarea :value="aspect.remarks" @change-remarks="changeRemarks" :aspect-number="aspectNumber" />
        </div>

        <div v-show="isStep2" class="check-bar">
            <div class="check-bar__title">
                <h5 class="mb-0">Aan de slag met dit aspect? Activeer!</h5>
            </div>

            <SlideCheckbox :aspect="aspect" @change-state="changeState" />
        </div>
    </div>
</template>

<script>
    import AutogrowTextarea from "@/components/AutogrowTextarea";
    import SlideCheckbox from "./SlideCheckbox";

    export default {
        name: 'ScanAspect',
        components: {SlideCheckbox, AutogrowTextarea},
        props: {
            aspect: Object,
            state: String,
            itemIndex: Number,
        },
        data() {
            return {
                remarks: ''
            }
        },
        computed: {
            isStep2() {
                return this.$props.state === 'step2';
            },
            aspectNumber() {
                return this.$props.itemIndex + 1;
            }
        },
        methods: {
            selectLevel(level) {
                if (this.$props.state !== 'step1') {
                    return;
                }

                if (this.isLevelDisabled(level)) {
                    return;
                }

                const aspect = this.$props.aspect;

                if(aspect.from !== null && aspect.to !== null && aspect.from === aspect.to && aspect.from === level) {
                    if (this.resetAspect()) {
                        return;
                    }
                }

                if (typeof aspect.from === 'undefined' || aspect.from === null) {
                    aspect.from = level;

                    level.isFrom = true;
                } else {
                    if (typeof aspect.to !== 'undefined' && aspect.to !== null) {
                        aspect.to.isTo = false;
                    }

                    aspect.to = level;

                    level.isTo = true;
                }

                aspect.remarks = this.remarks;
                this.$store.commit('SET_ASPECT', aspect);
            },
            isLevelDisabled(level) {
                const aspect = this.$props.aspect;

                if (aspect.from === null) {
                    return false;
                }

                if (aspect.from.index > level.index) {
                    return true;
                }

                if ((level.index - aspect.from.index) > 1) {
                    return true;
                }

                return false;
            },
            hasSettings() {
                const aspect = this.$props.aspect;

                if (aspect.from !== null) {
                    return true;
                }

                return false
            },
            resetAspect() {
                const aspect = this.$props.aspect;

                if (confirm('Wilt u dit aspect resetten?')) {
                    aspect.resetLevels();
                    return true;
                }

                return false;
            },
            changeRemarks(value) {
                this.remarks = value;
                this.$props.aspect.remarks = this.remarks;
            },
            changeState(isActive) {
                const aspect = this.$props.aspect;
                aspect.isChosen = isActive;
            }
        },
    }
</script>