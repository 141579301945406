<template>
    <div class="slide-checkbox">
        <label class="switch" :for="activatedCheckboxId">
            <input :id="activatedCheckboxId" v-model="checked" type="checkbox" :name="aspect.slug" @change="changeCheckbox()" >
            <div class="slider round"/>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'SlideCheckbox',
        components: {},
        props: {
            aspect: Object,
        },
        data() {
            return {
                checked: false
            }
        },

        computed: {
            activatedCheckboxId() {
                return 'activated-checkbox-' + this.aspect.id;
            },
        },
        mounted() {
            this.initialize();
        },

        methods: {
            initialize() {
                if (typeof this.$props.aspect.isChosen !== 'undefined' && this.$props.aspect.isChosen) {
                    this.checked = true;
                }
            },
            isActive() {
                return this.$el.querySelector('input[type="checkbox"]').checked;
            },
            changeCheckbox() {
                this.$emit('change-state', this.isActive());
            }
        }
        
    }
</script>