<template>
    <div class="input-group">
        <!-- TODO: wanneer textarea gevuld is moet de active class er op blijven staan. -->
        <textarea :id="`textarea${aspectNumber}`" v-model="remarks" class="input-group__input" name="" rows="1" :class="{ 'active': isActive }"/>

        <label :for="`textarea${aspectNumber}`" @click="selectInput(isActive)">
            Opmerkingen:
        </label>
    </div>
</template>

<script>
    export default {
        name: 'AutogrowTextarea',
        props: {
            value: String,
            aspectNumber: {type: Number, required: true},
        },
        data() {
            return {
                active: false,
            }
        },
        computed: {
            remarks: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('change-remarks', val);
                }
            },
            isActive() {
                return this.active || typeof this.remarks !== 'undefined';
            }
        },
        mounted() {
            this.$el.querySelector('textarea').addEventListener('keydown', (event) => {
                const el = event.target;
                setTimeout(() => {
                    el.style.cssText = 'height:auto; padding:0';
                    el.style.cssText = `height:${el.scrollHeight}px`;
                },0);
            });
        },
        methods: {
            selectInput() {
                this.active = !this.active;
            },
        }
    }
</script>