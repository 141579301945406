<template>
    <div>
        <h2 class="text-secondary">{{ theme.name }}</h2>

        <!-- Steps -->
        <div class="step-navigation">
            <div class="progress progress-bar-vertical step-navigation__progress">
                <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="50" class="progress-bar"
                     role="progressbar" style="width: 50%;"
                >
                    <span class="sr-only">50% Compleet</span>
                </div>
            </div>

            <ul class="step-navigation__inner">
                <li class="step">
                    <div class="d-flex flex-row justify-content-start align-items-center mb-2">
                        <div class="step__icon"><span><icon icon="pin"/></span></div>
                        <h4 class="step__title">stap 1</h4>
                    </div>
                    <div class="step__content">
                        <p>Bepalen huidige situatie en gewenste situatie.</p>
                    </div>
                </li>
                <li class="step disabled">
                    <div class="d-flex flex-row justify-content-start align-items-center mb-2">
                        <div class="step__icon"><span><icon icon="clipboard"/></span></div>
                        <h4 class="step__title">stap 2</h4>
                    </div>
                    <div class="step__content">
                        <p>Keuze van de aspecten.</p>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row mb-5">
            <div class="col-12 col-lg-8 order-1 order-lg-0">
                <div v-html="content"/>
            </div>
            <div class="col-12 col-lg-5 order-0 order-lg-1"/>
        </div>

        <!-- Aspect -->
        <div class="aspects">
            <scanAspect v-for="(aspect, index) in aspects.all()" :key="aspect.slug" :aspect="aspect" :item-index="index" state="step1" />
        </div>

        <!-- Button vorige & volgende -->
        <div class="d-flex justify-content-between align-content-center my-5 py-5">
            <printButton />
            
            <a href="#" class="btn btn-secondary" @click="nextStep($event)">
                volgende
                <span><icon icon="arrow-right"/></span>
            </a>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ScanAspect from '@/components/ScanAspect.vue'
    import Icon from "@/components/Icon";
    import api from "../../data/api";
    import AspectsCollection from '../../collections/AspectsCollection';
    import PrintButton from "@/components/PrintButton";

    export default {
        name: 'Home',
        components: {
            ScanAspect,
            Icon,
            PrintButton
        },
        data() {
            return {
                pageTitle: 'Scan',
                theme: {},
                aspects: new AspectsCollection()
            }
        },
        computed: {
            content() {
                if (typeof this.theme.analysesContent !== 'undefined') {
                    return this.theme.analysesContent.step1
                }
                
                return null;
            }
        },
        mounted() {
            if (typeof this.$store.state.roles === "undefined" || typeof this.$store.state.userRole === "undefined") {
                this.$store.dispatch('fetchRoles');
            }

            api.fetchTheme(this.$route.params.thema).then(response => {
                this.theme = response;

                api.fetchAspects(this.theme.id).then(response => {
                    this.aspects = response;
                });


                const breadcrumbs = [
                    {
                        'label': 'Home',
                        'path': '/'
                    },
                    {
                        'label': 'Analyse',
                        'path': '/analyse/'
                    },
                    {
                        'label': this.theme.pageTitle,
                        'path': '/analyse/' + this.theme.slug + '/'
                    },
                    {
                        'label': 'Stap 1',
                    },
                ];
                this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});

                this.$store.commit('SET_BREADCRUMBS_BUTTONS', {print: true});
            });
        },
        created() {
            this.theme = this.$store.getters.getThemeBySlug(this.$route.params.thema);
        },
        methods: {
            prevStep (event){
                event.preventDefault();

            },
            nextStep (event){
                event.preventDefault();
                
                if (this.validate()) {
                    this.$router.push({ path: `${this.$route.path}/stap-2` });
                } else {
                    alert('Nog niet alle aspect zijn correct ingevuld.');
                }
            },
            validate() {
                let isValid = true;
                this.aspects.all().forEach((aspect) => {
                    if (aspect.from === null ||  aspect.to === null) {
                        isValid = false;
                    }
                });

                return isValid;
            }
        },
    }
</script>
